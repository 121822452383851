/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

/*.btn-primary {*/
/*  color: #fff;*/
/*  background-color: #1b6ec2;*/
/*  border-color: #1861ac;*/
/*}*/

k-grid-content {
  overflow-y: auto !important;
}

.k-form .k-form-fieldset {
  margin: 0 !important;
}

.k-notification-content {
  font-size: 1rem;
}

.k-icon.k-i-error, .k-icon.k-i-close, .k-icon.k-i-success {
  font-size: 1.5rem !important;
}