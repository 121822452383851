.custom-scheduler .k-checkbox {
    border-color: #5F91AD !important;
    overflow: hidden !important;
    border-radius: 2px !important;
}

.custom-scheduler .k-checkbox:focus {
    box-shadow: none !important;
}

.custom-scheduler .k-checkbox:checked {
    background-color: #FFB703 !important;
}

.custom-scheduler .k-state-focused *, .custom-scheduler .k-timepicker:focus-within {
    box-shadow: none !important;
}

.custom-scheduler .k-dateinput, .custom-scheduler .k-timepicker {
    border-color: #5F91AD !important;
    width: 100px !important;
    border-radius: 3px !important;
    overflow: hidden !important;
}

.custom-scheduler .k-timepicker .k-select {
    border-left: 1px solid #5F91AD !important;
    background-color: #49768F;
    color: #FFF;
    overflow: hidden !important;
}

.custom-scheduler .k-timepicker .k-select:hover {
    background-color: #375A6D;
}

.custom-scheduler .k-dateinput .k-input:focus {
    box-shadow: none !important;
}

.custom-scheduler .form-select {
    border-color: #5F91AD !important;
}

.custom-scheduler .form-select:focus {
    box-shadow: none !important;
}

.custom-scheduler input[type="checkbox"] {
    margin-right: 3px;
}
