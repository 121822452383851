.heading{
    display: block;
    padding: 5px 0;
    position: relative;
}
.heading strong{
    color:#13364E;
    display: inline-block;
    padding-right: 10px;
    background-color: #FFF;
}
.heading .line{
    display: block;
    border-top:1px solid #E7F4F9;
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: -1;
}
