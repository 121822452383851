.info-notification {
    display: block;
    padding: 10px 15px;
    color: #1B3541;
    background-color: #DEF0F7;
    margin-bottom: 10px;
    font-size: 14px;
}
.info-notification .icon{
    margin-right: 5px;
    font-size: 14px;
    margin-top: -3px;
}
