.custom-scheduler-daily {
    display: block;
}

.time-selector {
    display: block;
    margin-bottom: 10px;
}

.time-selector .time-options {
    display: block;
    margin-bottom: 10px;
}

.time-selector .time-options .time-option {
    display: inline-block;
    width: calc(100% / 5);
}

.time-selector .time-custom {
    display: flex;
    width: 200px;
}

.time-selector .time-custom .btn-add-time {
    margin-left: 5px;
    background-color: #0072BC !important;
    color: #FFF !important;
    border-radius: 3px !important;
}

.time-selector .time-custom .btn-add-time:hover {
    background-color: #0072BC;
}

.time-selector .time-custom .btn-add-time .icon {
    font-size: 12px;
    margin-right: 1px;
    margin-top: -3px;
}
